import React from "react";
import CardContent from "../../components/CardContent/CardContent";
import { ImageRatios } from "../../components/Image/Image";
import ImageCard from "../../components/ImageCard/ImageCard";
import { urlForDocument } from "../../urls";
import { SanityProduct } from "../../model/buy";
import styles from "./ProductCard.module.scss";

interface ProductCardProps {
  product: SanityProduct;
}

const ProductCard = ({ product }: ProductCardProps) => {
  return (
    <ImageCard
      className={styles.card}
      widths={800}
      image={product.image}
      aspectRatio={ImageRatios.Landscape_4_3}
      href={urlForDocument(product)}
    >
      <CardContent
        title={product.title}
        titleVariant="h4"
        titleAs="h2"
        text={product.subtitle}
        href={urlForDocument(product)}
        textMaxLines={1}
      />
    </ImageCard>
  );
};

export default ProductCard;
